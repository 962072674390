/* Login Bar at the Top */
.loginbar-container {
  background-color: var(--loginbar-bg-color);
  color: white;
  padding: 0.3rem;
  display: grid;
  grid-template-areas:
    "hideShow"
    "menu";
  z-index: 99;
}

.loginbar-collapse {
  grid-area: hideShow;
  display: none;
  text-align: center;
  background-color: lightgrey;
  color: grey;
  font-weight: bold;
  padding: 1rem;
}

.loginbar-container .menuSection {
  grid-area: menu;
  display: grid;
  grid-template-areas: "username menu";
  grid-template-columns: 350px auto;
}

.loginbar-container .username {
  grid-area: username;
  margin-left: 1rem;
}

.loginbar-container .menu-container {
  grid-area: menu;
  justify-self: end;
  margin-right: 1rem;
}

.loginbar-menu-items {
  display: inline-flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  gap: 1rem;
}

.loginbar-menu-items a {
  outline: none;
  box-shadow: none;
  display: block;
  color: white;
  font-family: "Montserrat-Regular";
}

.loginbar-menu-items button {
  background: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 1rem;
  font-family: "Montserrat-Regular";
}

.loginbar-menu-items button:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* ======================================================= */
/*                        OTHER DEVICES                    */
/* ======================================================= */

/*  Medium Devices and smaller */
@media all and (max-width: 768px) {
  .loginbar-container {
    padding: 0;
  }
}

/* Small Devices */
@media all and (max-width: 576px) {
  .loginbar-container {
    margin-bottom: 2rem;
  }

  .loginbar-container > .loginbar-collapse {
    display: block;
  }

  .loginbar-container .menuSection {
    grid-area: menu;
    display: grid;
    grid-template-areas:
      "username"
      "menu";
    grid-template-columns: auto;
  }

  .loginbar-container .menuSectionHidden {
    display: none;
  }

  .loginbar-container .username {
    display: none;
  }

  /* Menu Container */
  .loginbar-container .menu-container {
    margin: 0;
    display: flex;
    justify-self: stretch;
    justify-content: center;
    border-bottom: 1px solid var(--nav-bg-color);
  }

  .menu-container > .loginbar-menu-items {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0;
  }

  .loginbar-menu-items li {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 4rem;
  }

  .loginbar-menu-items a {
    height: 100%;
    padding: 1rem;
  }

  .loginbar-menu-items button {
    width: 100%;
    height: 100%;
    padding: 1rem;
  }
}
