/* 404 NOT FOUND PAGE */
.nf-verticalhorizontal {
  display: flex;
  margin: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

img.nf {
  margin-bottom: 50px;
  max-width: 800px;
}
