/* FIELDS */
:root {
  --form-page-main-bg-color: #e6e6e6;
  --form-card-bg-color: #ffffff;
  --field-container-bg-color: #f7f7f7;
  --field-container-border-color: #e6e6e6;
  --field-container-options-bg-color: #028fbe;
}

.chart-container {
  height: 40vh;
}

/* The big card in the center of the page that holds the header, blurb and form */
.form-card {
  position: relative;
  margin: 2rem auto;
  padding: 1rem;
  background-color: var(--form-card-bg-color);
  max-width: 765px;
  border-radius: 10px;
  box-shadow: 0px 7px 4px -1px rgba(0, 0, 0, 0.25);
}

/* The form containing all the fields */
.form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: var(--form-card-bg-color);
  z-index: 1;
}

/* Field Container (inside is the label, indicator and field) */
.field-container {
  background-color: var(--field-container-bg-color);
  border: 1px solid var(--field-container-border-color);
  border-radius: 10px;
  padding: 10px 30px 9px 22px;
  z-index: 0;

  animation: animation-pop-in 0.5s;
  animation-fill-mode: both;

  /* Have to add this because iOS safari does not do flexbox gaps */
  margin: 5px;
}

.field-container.no-animation {
  animation: none;
}

.field-container.nobackground {
  background-color: var(--form-card-bg-color);
}

.field-container.fullwidth {
  width: 100%;
}

.field-container.halfwidth {
  width: calc(50% - 20px);
}

.field-container.options {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--field-container-options-bg-color);
  color: white;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 5px;
}

.field-container.options {
  margin-top: 10px;
}

.field-container.options:last-of-type {
  margin-bottom: 20px;
}

/* Field Labels */
.field-label {
  font-family: "Montserrat-SemiBold";
  line-height: 1.5;
  text-transform: uppercase;
  font-size: 0.7rem;
  padding-left: 3px;
}

/* Field Icon & Text (Tick) */
.field-indicator {
  float: right;
  font-family: "Montserrat-Regular";
  font-size: 0.7rem;
}

.field-indicator-image {
  height: 16px;
  width: 16px;
  float: right;
}

/* Input Fields (Text) */
.field-input {
  width: 100%;
  font-family: "Montserrat-SemiBold";
  font-size: 1rem;
  line-height: 1.2;
  outline: none;
  border: none;
  height: 40px;
  background: none;
}

.field-input::placeholder {
  opacity: 0.5;
}

/* Text Areas (Multi-Line Text) */
.field-textarea {
  background-color: var(--form-card-bg-color);
  resize: none;
  width: 100%;
  min-height: 250px;
  outline: none;
  border: none;
  overflow: auto;
  font-family: "Montserrat-SemiBold";
  font-size: 1rem;
}

.field-textarea::placeholder {
  opacity: 0.5;
}

/* Select (combobox / dropdown selection) */
.field-select {
  cursor: pointer;
  width: 100%;
  height: 2rem;
  font-family: "Montserrat-Regular";
  font-size: 1rem;
  padding-left: 5px;
  min-width: 300px;
}

/* Labels */
label.float-left {
  float: left;
}

/* Radio Input (Custom Style) */
/* The container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
}

/* Create a custom radio button */
.radio-container > .radio-circle {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .radio-circle {
  background-color: #ccc;
}

/* When the radio button is checked, add a highlight background */
.radio-container input:checked ~ .radio-circle {
  background-color: var(--nav-highlight-bg-hover-color);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-circle:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-circle:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-circle:after {
  width: 8px;
  height: 8px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: white;
}

/* Checkbox (Custom Style) */
/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
}

.checkbox-container.disabled {
  cursor: default;
  color: #afafaf;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-square {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 25px;
  width: 25px;
  background-color: white;
  border: solid 1px #6b6a6a;
}

/* Disabled checkbox */
.checkbox-square.disabled {
  cursor: default;
  background-color: #ccc;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkbox-square {
  background-color: #ccc;
}

/* When the checkbox is checked, alter background */
.checkbox-container input:checked ~ .checkbox-square:not(.disabled) {
  background-color: var(--nav-highlight-bg-hover-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-square:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkbox-square:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkbox-square:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* TABLES */
table.standard {
  width: 100%;
}

table.light-border {
  border: 1px solid lightgrey;
}

table.standard th {
  background-color: var(--loginbar-bg-color);
  color: white;
  font-family: "Montserrat-SemiBold";
  font-size: 1rem;
  text-align: left;
  padding-left: 5px;
  cursor: auto;
}

table.standard tr:nth-child(even) {
  background-color: var(--table-row-alternate-color);
}

table.standard td {
  padding-left: 5px;
}

table.standard:not(.no-hover) tr:hover {
  background-color: var(--nav-highlight-bg-hover-color);
  cursor: pointer;
}

/* Small version we space out each table */
table.small {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid lightgrey;
}

table.small tr:nth-child(even) {
  background-color: var(--table-row-alternate-color);
}

table.small:hover {
  background-color: var(--nav-highlight-bg-hover-color);
  cursor: pointer;
}

table.small:hover tr:nth-child(even) {
  background-color: var(--nav-highlight-bottom-color);
}

/* TABS */
/* Style the tab */
.tab-container {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  margin-bottom: 15px;
}

/* Style the buttons that are used to open the tab content */
.tab-container button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-family: "Montserrat-Regular";
  font-size: 1rem;
}

/* Change background color of buttons on hover */
.tab-container button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab-container button.active {
  background-color: #ccc;
}

/* Animations */

/* This is for the animation so they pop in one after the other */
/* (Extra nth childs have been specified incase additional elements have been added) */
.field-container:nth-of-type(1) {
  animation-delay: 0.25s;
}
.field-container:nth-of-type(2) {
  animation-delay: 0.5s;
}
.field-container:nth-of-type(3) {
  animation-delay: 0.75s;
}
.field-container:nth-of-type(4) {
  animation-delay: 1s;
}
.field-container:nth-of-type(5) {
  animation-delay: 1.25s;
}
.field-container:nth-of-type(6) {
  animation-delay: 1.5s;
}
.field-container:nth-of-type(7) {
  animation-delay: 1.75s;
}
.field-container:nth-of-type(8) {
  animation-delay: 2s;
}
.field-container:nth-of-type(9) {
  animation-delay: 2.25s;
}
.field-container:nth-of-type(10) {
  animation-delay: 2.5s;
}

/* ======================================================= */
/*                        OTHER DEVICES                    */
/* ======================================================= */

/*  Medium Devices and smaller */
@media all and (max-width: 768px) {
  .form-card {
    margin: 2rem;
  }

  .field-container.halfwidth {
    width: 100%;
  }
}

/*  Small Devices */
@media all and (max-width: 375px) {
  .form-card {
    margin: 1rem;
  }

  .field-select {
    min-width: 150px;
  }
}
