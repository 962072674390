/* ABOUT PAGE */

.about-blurb {
  margin-bottom: 75px;
}

/* SKILLS SECTION (TECHNOLOGIES) */
.about-skills-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 100px;
  gap: 20px;
  background-color: var(--section-text-bg-color);
  padding: 20px;
}

.about-skills-card {
  width: 245px;
  padding: 5px;

  animation: animation-pop-in 0.5s;
  animation-fill-mode: both;
}

.about-skills-card-content {
  background-color: var(--about-skills-card-content-bg-color);
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
}

.about-skills-card-title {
  font-family: "Montserrat-SemiBold";
  text-align: center;
}

.about-skills-card-subtitle {
  display: flex;
  align-items: center;
  background-color: var(--about-skills-card-subtitle-bg-color);
  border: 1px solid var(--about-skills-card-item-border-color);
  padding: 5px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.about-skills-card-subtitle.no-top-margin {
  margin-top: 0;
}

.about-skills-card-item {
  display: flex;
  align-items: center;
  background-color: var(--about-skills-card-item-bg-color);
  border: 1px solid var(--about-skills-card-item-border-color);
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0;
}

.about-skills-card-item:hover {
  background-color: var(--about-skills-card-item-hover-bg-color);
  cursor: pointer;
}

.about-skills-card-item.disabled:hover {
  cursor: auto;
}

.about-skills-card-item:first-child,
.about-skills-card-item:last-child {
  margin: 0;
}

.about-skills-card-item.selected {
  background-color: var(--about-skills-card-item-selected-bg-color);
  border: 1px solid var(--about-skills-card-item-selected-border-color);
}

.about-skills-card-item.indent {
  margin-left: 15px;
}

.about-skills-card-item img {
  height: 32px;
  margin-right: 5px;
}

.about-skills-card-item img.no-resize {
  height: auto;
}

.about-skills-card-item.disabled {
  background-color: transparent;
  color: var(--about-skills-card-item-border-color);

  animation: animation-skills-item-disabled 2s ease-in-out;
  animation-fill-mode: forward;
  animation-delay: 1s;
}

.about-skills-card-item.disabled img {
  filter: grayscale(1);
  opacity: 0.3;
}

/* Info bubble */
.about-skills-card-item-info {
  position: relative;
  margin: 20px 0;
  background-color: var(--about-skills-card-item-info-bg-color);
  color: white;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  font-size: 0.8rem;

  animation: animation-skills-card-item-info 3s linear;
  animation-fill-mode: forwards;
}

@keyframes animation-skills-card-item-info {
  0% {
    max-height: 300px;
    padding: 0;
    overflow: hidden;
  }
  100% {
    max-height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: none;
  }
}

/* Selected info bubble: triangle pointer on the top */
.about-skills-card-item-info::before {
  content: "";
  position: absolute;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid transparent;
  border-bottom: 12px solid var(--about-skills-card-item-info-bg-color);
  top: -24px;
  left: 108px;
}

/* PROFILES */
img.about-person {
  max-width: 250px;
}

/* This flexbox contains individuals info (image and text) */
div.about-person-container {
  display: grid;
  grid-template-areas: "pic info";
  grid-template-columns: 250px auto;
  width: 100%;
  margin-bottom: 100px;
  gap: 20px;
}

div.about-person-container.no-bottom-margin {
  margin-bottom: 0;
}
.about-person-container .about-person-img {
  grid-area: pic;
  position: relative;
  width: 250px;
  height: 250px;
  background-repeat: no-repeat;
  background-size: contain;
}

.about-person-container .about-person-info {
  grid-area: info;
}

.about-person-title {
  display: grid;
  grid-template-areas: "name email linkedIn skills";
  color: white;
  font-family: "Montserrat-SemiBold";
  border-radius: 5px;
  min-height: 3rem;
  line-height: 3rem;
  text-align: center;
  margin-bottom: 20px;
}

.about-person-title img {
  height: 25px;
  margin-right: 7px;
  vertical-align: middle;
}

.about-person-title .name {
  grid-area: name;
  background-color: var(--section-header-bg-color);
  padding: 5px;
  margin-right: 15px;
  border-radius: 5px;
  min-width: 200px;
}

.about-person-title .email {
  grid-area: email;
  background-color: var(--nav-bg-color);
  border: 1px solid var(--nav-bg-color);
  border-radius: 5px 0 0 5px;
  padding: 5px;
  margin-right: 2px;
}

.about-person-title .linkedIn {
  grid-area: linkedIn;
  background-color: var(--nav-bg-color);
  border: 1px solid var(--nav-bg-color);
  padding: 5px;
  margin-right: 2px;
}

.about-person-title .skills {
  grid-area: skills;
  background-color: var(--nav-bg-color);
  border: 1px solid var(--nav-bg-color);
  border-radius: 0 5px 5px 0;
  padding: 5px;
}

.about-person-title .email:hover,
.linkedIn:hover,
.skills:hover {
  background-color: var(--nav-bg-hover-color);
  cursor: pointer;
}

.about-person-title .skills.selected {
  background-color: var(--about-skills-card-item-selected-bg-color);
  border: 1px solid var(--about-skills-card-item-selected-border-color);
  color: black;
}

.about-person-title .skills.selected img {
  filter: invert(1);
}

/* Links should be white in the info header bar */
.about-person-title a {
  color: white;
}

/* Clock */
.clock {
  width: 60px;
  height: 60px;
  border: 4px solid var(--clock-border-color);
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 10px;
}

.clock-numbers {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: var(--clock-number-bg-color);
  overflow: hidden;
}

.clock-numbers::after {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
}

.clock-numbers::before,
.clock-numbers::after,
.clock-numbers .marking {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  background: var(--clock-number-color);
  z-index: 0;
  left: 49%;
}

.clock-numbers .marking {
  width: 1px;
}

.clock-numbers .marking.marking-one {
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  transform: rotate(30deg);
}

.clock-numbers .marking.marking-two {
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  transform: rotate(60deg);
}

.clock-numbers .marking.marking-three {
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  transform: rotate(120deg);
}

.clock-numbers .marking.marking-four {
  -webkit-transform: rotate(150deg);
  -moz-transform: rotate(150deg);
  transform: rotate(150deg);
}

.clock-numbers .inner-clock-face {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  background: #fefefc;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  z-index: 1;
}

.inner-clock-face::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 18px;
  margin-left: -5px;
  margin-top: -5px;
  background: var(--clock-hand-color);
  z-index: 11;
}

.clock-numbers .hand {
  width: 50%;
  right: 50%;
  height: 2px;
  background: var(--clock-hand-color);
  position: absolute;
  top: 50%;
  border-radius: 6px;
  transform-origin: 100%;
  transform: rotate(90deg);
  transition-timing-function: cubic-bezier(0.1, 2.7, 0.58, 1);
}

.hand.hour-hand {
  width: 30%;
  z-index: 3;
}

.hand.min-hand {
  height: 2px;
  z-index: 10;
  width: 45%;
}

.hand.second-hand {
  background: var(--clock-hand-second-color);
  width: 50%;
}

/* ANIMATIONS */

/* This is for the animation so they pop in one after the other */
/* (Extra nth childs have been specified incase additional elements have been added) */
.about-skills-card:nth-of-type(1) {
  animation-delay: 0.5s;
}
.about-skills-card:nth-of-type(2) {
  animation-delay: 0.75s;
}
.about-skills-card:nth-of-type(3) {
  animation-delay: 1s;
}
.about-skills-card:nth-of-type(4) {
  animation-delay: 1.25s;
}
.about-skills-card:nth-of-type(5) {
  animation-delay: 1.5s;
}
.about-skills-card:nth-of-type(6) {
  animation-delay: 1.75s;
}
.about-skills-card:nth-of-type(7) {
  animation-delay: 2s;
}
.about-skills-card:nth-of-type(8) {
  animation-delay: 2.25s;
}
.about-skills-card:nth-of-type(9) {
  animation-delay: 2.5s;
}
.about-skills-card:nth-of-type(10) {
  animation-delay: 2.75s;
}

@keyframes animation-skills-item-disabled {
  100% {
    border: 1px solid var(--about-skills-card-item-border-color);
  }
}

@keyframes animation-skills-card-item-info {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 1000px;
    display: block;
  }
}

/* ======================================================= */
/*                        OTHER DEVICES                    */
/* ======================================================= */

/*  Medium Devices and smaller */
@media all and (max-width: 768px) {
  .about-skills-section {
    justify-content: center;
  }

  .about-person-img {
    justify-self: center;
  }

  div.about-person-container {
    grid-template-areas:
      "pic"
      "info";
    grid-template-columns: auto;
  }

  .about-person-title {
    grid-template-areas:
      "name name name"
      "email linkedIn skills";
    grid-template-rows: 3rem auto;
    column-gap: 2px;
  }

  .about-person-title .name {
    justify-self: stretch;
    padding: 0.5rem;
    margin-right: 0;
    line-height: 2rem;
    border-radius: 5px 5px 0 0;
  }

  .about-person-title .email {
    justify-self: stretch;
    margin-right: 0;
    padding: 0.5rem;
    border-radius: 0 0 0 5px;
  }

  .about-person-title .linkedIn {
    justify-self: stretch;
    margin-right: 0;
    padding: 0.5rem;
  }

  .about-person-title .skills {
    justify-self: stretch;
    padding: 0.5rem;
    border-radius: 0 0 5px 0;
  }

  .section-text {
    text-align: center;
  }
}

/*  Small Devices */
@media all and (max-width: 375px) {
  .about-person-title {
    grid-template-areas:
      "name"
      "email"
      "linkedIn"
      "skills";
    line-height: normal;
  }

  .about-person-title img {
    display: block;
    margin: auto;
    padding-bottom: 10px;
  }

  .about-person-title .email {
    border-bottom: solid 2px var(--body-bg-color);
    border-right: none;
    border-radius: 0;
  }

  .about-person-title .linkedIn {
    border-bottom: solid 2px var(--body-bg-color);
    border-right: none;
  }

  .about-person-title .skills {
    border-radius: 0 0 5px 5px;
  }
}
