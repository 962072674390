:root {
  --footer-bg-color: #3a3a3a;
  --footer-height: 25rem;
  --footer-divider-color: #808080;
}

body {
  background-color: var(--empty-space-bg-color);
}

/* FOOTER */
div.footer {
  display: grid;
  grid-template-areas:
    "section1 section2 section3"
    "bottom bottom bottom";
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px 2rem;
  justify-items: stretch;
  align-content: start;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--footer-bg-color);
  color: white;
}

div.footer .footer-left {
  grid-area: section1;
  overflow: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

div.footer .footer-middle {
  grid-area: section2;
  overflow: auto;
}

div.footer .footer-right {
  grid-area: section3;
  overflow: auto;
}

div.footer-bottom {
  grid-area: bottom;
  border-top: 1px solid var(--footer-divider-color);
  margin-bottom: 1rem;
  padding: 1rem 0 0 2rem;
}

/* FONTS */
h3.footer-font {
  font-family: "Montserrat-SemiBold";
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.footer a {
  color: white;
}

/* ======================================================= */
/*                        OTHER DEVICES                    */
/* ======================================================= */

/*  Medium Devices and smaller */
@media all and (max-width: 768px) {
  :root {
    --footer-height: 80rem;
  }

  div.footer {
    grid-template-areas:
      "section1"
      "section2"
      "section3"
      "bottom";
    grid-template-columns: 1fr;
  }

  div.footer .footer-left {
    overflow: visible;
    border-bottom: 1px solid var(--footer-divider-color);
    padding: 2rem;
  }

  div.footer .footer-middle {
    overflow: visible;
    border-bottom: 1px solid var(--footer-divider-color);
    padding: 2rem;
  }

  div.footer .footer-right {
    overflow: visible;
    border-bottom: 1px solid var(--footer-divider-color);
    padding: 2rem;
  }

  div.footer-bottom {
    border-top: none;
    padding: 2rem;
  }
}
