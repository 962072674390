/* LOGIN PAGE */
:root {
  --login-env-height: 46px;
  --login-env-width: 70px;
}

.login-card {
  position: relative;
  margin: 2rem auto;
  padding: 1rem;
  background-color: var(--login-bg-color);
  max-width: 765px;
  border-radius: 10px;
  box-shadow: 0px 7px 4px -1px rgba(0, 0, 0, 0.25);
}

#login-form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
