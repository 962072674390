/* SERVICES PAGE */

.services-blurb {
  margin-bottom: 75px;
}

div.services-card {
  margin-bottom: 100px;
}

/* This is just to hold the header and everything so they can pop in */
div.services-card.animation-pop-in {
  animation: animation-pop-in ease-out 0.5s;
  animation-fill-mode: both;
}

/* This grid contains the image and text for each service we provide */
div.services-container {
  display: grid;
  grid-template-areas: "pic info";
  grid-template-columns: 250px auto;
  width: 100%;
  gap: 20px;
}

div.middle-content > div.services-card:last-of-type {
  margin-bottom: 0;
}

div.services-container.flipped {
  grid-template-areas: "info pic";
  grid-template-columns: auto 250px;
}

img.services {
  max-width: 250px;
  grid-area: pic;
}

/* ANIMATIONS */

/* This is for the animation so they pop in one after the other */
/* (Extra nth childs have been specified incase additional elements have been added) */
div.services-card:nth-of-type(1) {
  animation-delay: 0.25s;
}
div.services-card:nth-of-type(2) {
  animation-delay: 0.5s;
}
div.services-card:nth-of-type(3) {
  animation-delay: 0.75s;
}
div.services-card:nth-of-type(4) {
  animation-delay: 1s;
}
div.services-card:nth-of-type(5) {
  animation-delay: 1.25s;
}
div.services-card:nth-of-type(6) {
  animation-delay: 1.5s;
}
div.services-card:nth-of-type(7) {
  animation-delay: 1.75s;
}
div.services-card:nth-of-type(8) {
  animation-delay: 2s;
}
div.services-card:nth-of-type(9) {
  animation-delay: 2.25s;
}
div.services-card:nth-of-type(10) {
  animation-delay: 2.5s;
}

/* ======================================================= */
/*                        OTHER DEVICES                    */
/* ======================================================= */

/*  Medium Devices and smaller */
@media all and (max-width: 768px) {
  .about-skills-section {
    justify-content: center;
  }

  img.services {
    justify-self: center;
  }

  div.services-container,
  div.services-container.flipped {
    grid-template-areas:
      "pic"
      "info";
    grid-template-columns: auto;
  }

  .section-text {
    text-align: center;
  }
}

/*  Small Devices */
@media all and (max-width: 375px) {
}
