/* Menu Bar at the Top */
.nav-container {
  position: relative;
  display: grid;
  grid-template-areas: "logo-container menu";
  grid-template-columns: 300px auto;
  background-color: var(--nav-bg-color);
  align-items: center;
  z-index: 98;
}

/* Hamburger Menu */
.hamburger-menu {
  position: absolute;
  z-index: 50;
  height: 40px;
  width: 40px;
  top: 5px;
  left: 5px;
}

/* Holds hidden checkbox and slices */
.hamburger-menu-content {
  position: relative;
  height: 100%;
}

/* Hidden Checkbox that is over the slices */
.hamburger-menu-content input {
  position: absolute;
  z-index: 2;
  opacity: 1;
  top: -4px;
  left: -4px;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.hamburger-menu-slices {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  padding: 3px 6px;
  border: none;
}

.slice {
  width: 100%;
  height: 5px;
  background-color: rgb(116, 116, 116);
  border-radius: 6px;
  margin: 5px 0;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

.slice:hover {
  background-color: var(--nav-bg-hover-color);
}

.slice:nth-of-type(1) {
  background-color: var(--nav-hover-bottom-color);
}

.slice:nth-of-type(2) {
  background-color: var(--nav-hover-bottom-color);
}

.slice:nth-of-type(3) {
  background-color: var(--nav-hover-bottom-color);
}

/* Transform into crossmark */
.hamburger-menu-content
  input:checked
  ~ .hamburger-menu-slices
  > .slice:nth-of-type(1) {
  transform: rotate(45deg) translate(5px, 8px);
}
.hamburger-menu-content
  input:checked
  ~ .hamburger-menu-slices
  > .slice:nth-of-type(2) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
.hamburger-menu-content
  input:checked
  ~ .hamburger-menu-slices
  > .slice:nth-of-type(3) {
  transform: rotate(-45deg) translate(6px, -9px);
}

/* Logo Container */
.nav-container > .logo-container {
  grid-area: logo-container;
  display: grid;
  grid-template-areas: "logo text";
  grid-template-columns: 120px auto;
  margin-left: 1rem;
  cursor: pointer;
  z-index: 10;
}

/* Images and Text Inside Logo Container */
#logo-black {
  width: 120px;
  transform: translate(0%, 25%);
  outline: none;
  box-shadow: none;
}

#logo-color {
  width: 120px;
  transform: translate(0%, 25%);
  outline: none;
  box-shadow: none;
}

.logo-container .below {
  grid-area: logo;
  z-index: 1;
  justify-self: end;
}
.logo-container .above {
  grid-area: logo;
  z-index: 2;
  animation: logo-animation 3s;
}

.logo-container .text {
  grid-area: text;
  color: white;
  font-family: "Quicksand-Bold";
  border-left: 2px solid white;
  padding-left: 10px;
  margin-left: 10px;
  align-self: center;
  animation: text-focus-in 5s;
}

.logo-container .text > a {
  text-decoration: none;
  color: white;
  outline: none;
  box-shadow: none;
}

/* Menu Container */
.nav-container > .menu-container {
  grid-area: menu;
  justify-self: end;
  margin-right: 1rem;
  background-color: var(--nav-bg-color);
  height: auto;
  transform: none;
  transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

.nav-container > .menu-container.hide {
  transform-origin: 0% 0%;
  transform: translate(0, -100%);
  height: 0px;
}

/* Menu Items */
.menu-items {
  display: inline-flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
}

.menu-items li {
  display: list-item;
}

/* Hide the menu items when hamburger is clicked */
.menu-container.hide .menu-items li {
  display: none;
}

.menu-items a {
  text-decoration: none;
  outline: none;
  box-shadow: none;
  display: block;
  padding: 1rem;
  color: white;
  font-family: "Montserrat-Regular";
  border-bottom: 3px solid var(--nav-bg-color);
}

.menu-items a:hover {
  background: var(--nav-bg-hover-color);
  border-bottom: 3px solid var(--nav-hover-bottom-color);
}

.menu-items a.menu-highlight {
  background: var(--nav-highlight-bg-color);
  border-bottom: 3px solid var(--nav-highlight-bg-color);
}

.menu-items a.menu-highlight:hover {
  background: var(--nav-highlight-bg-hover-color);
  border-bottom: 3px solid var(--nav-highlight-bottom-color);
}

/* Animations and keyframes */
@keyframes logo-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes text-focus-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ======================================================= */
/*                        OTHER DEVICES                    */
/* ======================================================= */

/*  Medium Devices and smaller */
@media all and (max-width: 768px) {
  .nav-container {
    grid-template-areas:
      "logo-container"
      "menu";
    grid-template-columns: auto;
    justify-items: center;
  }

  /* Logo Container */
  .nav-container > .logo-container {
    margin: 0;
    padding: 0.5rem;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid var(--nav-bg-hover-color);
  }

  .nav-container > .logo-container {
    background-color: white;
  }

  .logo-container .text {
    color: black;
    border-left: 2px solid black;
  }

  .logo-container .text > a {
    color: black;
  }

  /* Menu Container */
  .nav-container > .menu-container {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    margin: 0;
    display: flex;
    justify-self: stretch;
    justify-content: space-evenly;
    position: relative;
  }

  .menu-items {
    padding: 0;
  }
}

/* Small Devices */
@media all and (max-width: 576px) {
  .nav-container {
    position: relative;
  }

  .menu-container > .menu-items {
    display: block;
    width: 100%;
    text-align: center;
  }

  .menu-items li {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .bottom-shadow {
    box-shadow: none;
  }
}

/* Very Small Devices */
@media all and (max-width: 320px) {
  .hamburger-menu {
    left: 0;
  }
  .nav-container > .logo-container {
    padding-left: 40px;
  }
}
