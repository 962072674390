/* CONTACT-US PAGE */
:root {
  --contact-env-height: 46px;
  --contact-env-width: 70px;
  --contact-env-top-color: #05698a;
  --contact-env-left-color: #028fbe;
  --contact-env-bottom-color: #038cba;
  --contact-env-right-color: #0580a9;
}

/* This hides the form from appearing below the bottom of the envelope */
.contact-hidden-background {
  position: absolute;
  left: 0;
  top: 370px;
  width: 100%;
  height: 220px;
  background-color: var(--form-card-bg-color);
  z-index: -1;
}

/* Envelope */
.contact-envelope {
  position: absolute;
  top: 360px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border-radius: 6px;
  box-shadow: 0px 0px 3px #000;

  border-top: var(--contact-env-height) solid var(--contact-env-top-color);
  border-left: var(--contact-env-width) solid var(--contact-env-left-color);
  border-bottom: var(--contact-env-height) solid var(--contact-env-bottom-color);
  border-right: var(--contact-env-width) solid var(--contact-env-right-color);
  border-radius: 6px;
  z-index: 2;
  opacity: 0;
}

.contact-envelope-lid {
  position: absolute;
  top: 360px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-top: var(--contact-env-height) solid var(--contact-env-top-color);
  border-left: var(--contact-env-width) solid transparent;
  border-bottom: var(--contact-env-height) solid transparent;
  border-right: var(--contact-env-width) solid transparent;
  border-radius: 6px;
  z-index: -1;
}

/* Animations */
.form-container.letter-animation {
  animation: letter-animation 3.5s forwards;
}

.contact-envelope.envelope-show-animation {
  animation: envelope-show-animation 3.5s forwards;
  animation-delay: 1.5s;
}

.contact-envelope-lid.open-close-animation {
  animation: open-close-animation 3.5s ease-in forwards;
  animation-delay: 1.5s;
}

.contact-hidden-background.show-hidden-background-animation {
  animation: show-hidden-background-animation forwards;
  animation-delay: 0.75s;
}

@keyframes letter-animation {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.1);
    transform-origin: top;
    top: 0px;
  }
  70% {
    top: 0px;
  }
  100% {
    border: 1px solid #5f5f5f;
    transform-origin: top;
    transform: scale(0.15);
    top: 150px;
    z-index: 2;
    opacity: 1;
  }
}

@keyframes show-hidden-background-animation {
  0% {
    z-index: -1;
  }
  100% {
    z-index: 2;
  }
}

@keyframes envelope-show-animation {
  /* Fade In Effect */
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  /* Make the lid transparent for open close effect */
  20% {
    border-top: var(--contact-env-height) solid transparent;
  }
  95% {
    border-top: var(--contact-env-height) solid transparent;
  }
  /* Make the lid solid for close */
  96% {
    border-top: var(--contact-env-height) solid var(--contact-env-top-color);
  }
  100% {
    opacity: 1;
    border-top: var(--contact-env-height) solid var(--contact-env-top-color);
  }
}

/* open and closing envelope animation */
@keyframes open-close-animation {
  /* Start off closed */
  0% {
    opacity: 0;
    transform-origin: top center;
    top: 360px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
  10% {
    opacity: 1;
  }
  /* Open */
  20% {
    transform-origin: top center;
    transform: rotateX(180deg) translate(-50%, 50%);
    z-index: 0;
  }
  /* Keep Open until...(start closing at) */
  80% {
    transform-origin: top center;
    transform: rotateX(180deg) translate(-50%, 50%);
  }
  /* Fully Closed */
  95% {
    transform-origin: top center;
    top: 360px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    opacity: 1;
  }
  /* Hide the lid that opens and closes (original envelope now same colour) */
  100% {
    opacity: 0;
  }
}

/* ======================================================= */
/*                        OTHER DEVICES                    */
/* ======================================================= */

/*  Medium Devices and smaller */
@media all and (max-width: 768px) {
}

/*  Small Devices */
@media all and (max-width: 375px) {
  :root {
    --contact-env-height: 32px;
    --contact-env-width: 50px;
  }
}
