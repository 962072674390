:root {
  --empty-space-bg-color: #f8f8f8;
  --loginbar-bg-color: #064d7d;
  --nav-bg-color: #028fbe;
  --nav-bg-hover-color: #02bdec;
  --nav-hover-bottom-color: #1565c0;
  --nav-highlight-bg-color: #ee6707;
  --nav-highlight-bg-hover-color: #fa812b;
  --nav-highlight-bottom-color: #d95d04;
  --body-bg-color: #fff;
  --card-bg-color: #e6dfdf;
  --card-width: 290px;
  --scrollbar-bg-color: #e6dfdf;
  --scrollbar-color: #b5b5b5;
  --scrollbar-hover-color: #888888;
  --button-standard-disabled-color: #888888;
  --section-header-bg-color: #064d7d;
  --section-text-bg-color: #d5f5ffa4;
  --about-skills-card-content-bg-color: #f0f0f0;
  --about-skills-card-subtitle-bg-color: #d6d6d6;
  --about-skills-card-item-bg-color: white;
  --about-skills-card-item-hover-bg-color: #7fdffca4;
  --about-skills-card-item-selected-bg-color: #fffbbe;
  --about-skills-card-item-selected-border-color: #fa812b;
  --about-skills-card-item-border-color: #d6d6d6;
  --about-skills-card-item-info-bg-color: #8a8a8a;
  --clock-border-color: #064d7d;
  --clock-number-color: #b8b8c5;
  --clock-number-bg-color: #e2e2e2;
  --clock-hand-color: black;
  --clock-hand-second-color: #f74a4a;
  --admin-container-border-color: #e6e6e6;
  --table-row-alternate-color: #ebebeb;
}

html {
  scroll-behavior: smooth;
}

/* GENERAL */
.align-right {
  text-align: right;
}

/* LINKS */
a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a.normal {
  color: var(--nav-hover-bottom-color);
}

a.button-highlight-link {
  grid-area: button;
  border: none;
  outline: none;
  background-color: var(--nav-highlight-bg-color);
  color: white;
  text-decoration: none;
  cursor: pointer;
  padding: 1rem;
  border-radius: 6px;
  right: 2rem;
  font-size: 1rem;
  max-width: 10rem;
  justify-self: end;
}

a.button-highlight-link:hover {
  background-color: var(--nav-highlight-bg-hover-color);
}

a.no-hover-decoration:hover {
  text-decoration: none;
}

/* Admin Container (the border around the admin component (list of fields) you selected) */
.admin-form-border {
  margin-top: 20px;
  min-height: 250px;
  width: 100%;
  border: 1px solid var(--admin-container-border-color);
  border-radius: 10px;
  padding: 20px;
}

/* Toggles an element so it can be hidden from view */
.hidden {
  display: none;
}

.center-horizontally {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.fullwidth {
  width: 100%;
}

div.inline {
  display: inline-block;
}

/* Images */
img.invert {
  filter: invert(1);
}

img.small {
  height: 32px;
}

img.medium {
  height: 64px;
}

img.large {
  height: 128px;
}

/* Button */
button.standard {
  border: none;
  outline: none;
  background-color: var(--nav-bg-color);
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 6px;
  font-family: "Montserrat-SemiBold";
  font-size: 1rem;
}

button.standard:hover {
  background-color: var(--nav-bg-hover-color);
}

button.disabled {
  background-color: var(--button-standard-disabled-color);
  cursor: default;
}

button.disabled:hover {
  background-color: var(--button-standard-disabled-color);
}

/* TOP BAR */
.topbar-container {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
}

/* SECTION HEADER & TEXT */
.section-header {
  position: relative;
  background-color: var(--section-header-bg-color);
  color: white;
  font-family: "Montserrat-SemiBold";
  padding: 5px 5px 5px 10px;
  border-radius: 5px;
  margin: 20px 0;
  min-height: 3rem;
  width: 100%;
}

.section-header.highlight {
  background-color: var(--nav-bg-hover-color);
}

.section-header img {
  position: absolute;
  height: 25px;
  margin-right: 7px;
  top: 50%;
  transform: translate(0, -50%);
}

.section-header > .text {
  position: absolute;
  margin-left: 35px;
  top: 50%;
  transform: translate(0, -50%);
}

.section-text {
  border-radius: 5px;
  background-color: var(--section-text-bg-color);
  padding: 1rem;
}

/* SCROLL TO TOP */
#btnScrollToTop {
  display: block;
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: var(--nav-bg-color);
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 6px;
  font-family: "Montserrat-SemiBold";
  font-size: 1rem;
}

#btnScrollToTop:hover {
  background-color: var(--nav-bg-hover-color);
}

/* BODY & CONTAINERS */
body {
  background-color: var(--empty-space-bg-color);
  font-family: "Montserrat-Regular";
  line-height: 2;
  margin: 0px;
}

#body-container {
  position: relative;
  min-height: 100vh;
  max-width: 2000px;
  margin: 0px auto;
  background-color: var(--body-bg-color);
}

div.main-content {
  padding-bottom: var(--footer-height);
}

.standard-margin {
  margin: 2rem;
}

a.nostyle:link {
  text-decoration: inherit;
  color: inherit;
}

a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
}

/* TOP */
.banner {
  height: auto;
  max-width: 100%;
  content: url("../images/css/BannerLarge.svg");
}

/* MIDDLE */
div.middle-content {
  padding: 1rem 2rem;
}

/* CARDS */
div.flex-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin: 2rem 0px;
}

.card-container {
  margin: 10px;
}

.card-header {
  position: relative;
  text-align: center;
  background-color: var(--body-bg-color);
}

.card-avatar {
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: solid 4px var(--nav-bg-color);
  background-color: white;
}

.card-header-top {
  height: 40px;
}

.card-header-middle {
  background-color: var(--nav-bg-color);
  height: 5px;
}

.card-header-bottom {
  height: 40px;
  background-color: var(--card-bg-color);
}

.card-content {
  width: var(--card-width);
  background-color: var(--card-bg-color);
  padding: 1rem;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 7px 4px -1px rgba(0, 0, 0, 0.25);
  min-height: 18rem;
}

/* STRIPE */
.stripe {
  display: grid;
  grid-template-areas: "image blurb button";
  grid-template-columns: 60px auto auto;
  gap: 1rem;
  padding: 2rem;
  background-image: linear-gradient(to right, #028fbe, #b8dae4);
  color: white;
}

.stripe-image {
  grid-area: image;
  height: 60px;
  width: 60px;
  align-self: center;
}

.stripe-blurb {
  grid-area: blurb;
  align-self: center;
  font-family: "Montserrat-SemiBold";
}

/* COMPANY LOGOS */
.flex-logos-home {
  display: flex;
  flex-wrap: wrap;
}

img.companylogo-home {
  height: 150px;
  width: 200px;
  border: 1px solid lightgrey;
  margin: 5px;
}

/* FONTS */
h2.center-font {
  text-align: center;
  margin-bottom: 2rem;
  font-family: "Montserrat-SemiBold";
}

.center-font {
  text-align: center;
}

.subheader-font {
  font-family: "Montserrat-SemiBold";
  font-size: 1.2rem;
}

/* SCROLL BARS */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-bg-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-hover-color);
}

/* INFORMATION SECTION */
/* (Used by various pages for a tabular info block with blue header stripe at the top) */
.info-title {
  background-color: var(--section-header-bg-color);
  color: white;
  padding: 0.5rem 1rem;
  font-family: "Montserrat-SemiBold";
  border-radius: 6px;
  margin-bottom: 20px;
  animation: animation-pop-in;
}

.info-title img {
  height: 25px;
  margin-right: 1rem;
  vertical-align: middle;
}

.info-text {
  padding: 0 1rem;
}

.info-text > ul {
  padding-left: 1rem;
}

/* ======================================================= */
/*                        OTHER DEVICES                    */
/* ======================================================= */

/*  Medium Devices and smaller */
@media all and (max-width: 768px) {
  .banner {
    content: url("../images/css/BannerMedium.svg");
  }

  .section-header {
    text-align: center;
  }

  .section-header img {
    position: relative;
    display: block;
    left: 50%;
    top: 0;
    margin: 0;
    transform: translate(-50%, 0);
  }

  .section-header > .text {
    position: relative;
    margin-left: 0;
    top: 0;
    transform: translate(0, 0);
  }

  div.flex-cards {
    justify-content: center;
  }

  .stripe {
    grid-template-areas:
      "image"
      "blurb"
      "button";
    grid-template-columns: auto;
  }

  .stripe-image {
    justify-self: center;
  }

  .stripe-blurb {
    text-align: center;
  }

  a.button-highlight-link {
    justify-self: center;
  }

  .flex-logos-home {
    justify-content: center;
  }
}

/* Small devices only */
@media all and (max-width: 576px) {
  :root {
    --card-width: 70vw;
  }

  .banner {
    content: url("../images/css/BannerSmall.svg");
  }

  img.companylogo-home {
    height: 75px;
    width: 100px;
  }
}
