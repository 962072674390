@font-face {
  font-family: Quicksand-Regular;
  src: url(../fonts/Quicksand-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: Quicksand-SemiBold;
  src: url(../fonts/Quicksand-SemiBold.ttf);
  font-display: swap;
}

@font-face {
  font-family: Quicksand-Bold;
  src: url(../fonts/Quicksand-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: Montserrat-Regular;
  src: url(../fonts/Montserrat-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url(../fonts/Montserrat-SemiBold.ttf);
  font-display: swap;
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(../fonts/Montserrat-Bold.ttf);
  font-display: swap;
}
