ol.tc-abc {
  list-style-type: lower-latin;
}

ol > li {
  padding: 0.5rem;
}

.last-updated {
  color: grey;
  float: right;
}
