:root {
  --modal-bg-color: rgba(0, 0, 0, 0.8);
}

/* The Modal (background) */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--modal-bg-color);
}

/* Modal Content */
.modal > .modal-content {
  position: relative;
  width: 900px;
}

/* Top Bar */
.modal-content > .top-bar {
  background-color: var(--nav-bg-color);
  padding: 5px;
}

/* Standard content */
.modal-content > .modal-body {
  background-color: white;
  padding: 5px;
}

/* The Close Button */
.top-bar > #btnClose {
  display: flex;
  height: 50px;
  width: 50px;
  margin-left: auto;
  margin-right: 0;
  color: white;
  text-decoration: none;
  cursor: pointer;
  border-radius: 6px;
  font-size: 3rem;
  justify-content: center;
  align-items: center;
}

.top-bar > #btnClose:hover,
.top-bar > #btnClose:focus {
  background-color: var(--nav-bg-hover-color);
}

/* Show a slide */
.modal-content > .modal-slide {
  display: block;
  background-color: white;
}

.modal-slide > img {
  width: 100%;
  height: auto;
}

/* Next & previous buttons */
.modal-content > .prev,
.modal-content > .next {
  cursor: pointer;
  position: absolute;
  top: 45%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: darkgrey;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
  text-decoration: none;
}

/* On hover, add a background */
.modal-content > .prev:hover,
.modal-content > .next:hover {
  color: white;
  background-color: var(--nav-bg-color);
}

/* Position the "next button" to the right */
.modal-content > .next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a background */
.modal-content > .prev:hover,
.modal-content > .next:hover {
  background-color: var(--nav-bg-hover-color);
}

/* Number text (1/3 etc) */
.modal-slide > .modal-slide-number {
  color: darkgray;
  padding: 8px 12px;
  position: absolute;
  font-family: "Montserrat-SemiBold";
}

/* Caption text */
.modal-content > .caption-container {
  text-align: center;
  background-color: var(--nav-bg-color);
  padding: 5px;
  color: white;
  font-family: "Montserrat-SemiBold";
}

/* Thumbnails */
.modal-content > .scrolling-thumbnails {
  padding: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  background-color: white;
  text-align: center;
}

.scrolling-thumbnails > img.modal-thumbnail {
  opacity: 0.6;
  border: 2px solid lightgrey;
  background-color: white;
}

img.modal-thumbnail {
  width: 150px;
}

img.modal-thumbnail.active {
  opacity: 1;
  border: 2px solid var(--nav-bg-color);
}

img.modal-thumbnail:hover {
  cursor: pointer;
  border: 2px solid var(--nav-highlight-bg-hover-color);
}

/* ======================================================= */
/*                        OTHER DEVICES                    */
/* ======================================================= */

/* Standard Devices (small laptops & iPad Pro) */
@media all and (max-width: 2560px) {
  .modal > .modal-content {
    width: 800px;
  }
}

/* Large Laptops, laptops and tablets */
@media all and (max-width: 1440px) {
  .modal > .modal-content {
    width: 600px;
  }
}

/* Large Phone */
@media all and (max-width: 425px) {
  .modal > .modal-content {
    width: 405px;
  }
}

/* Small devices only */
@media all and (max-width: 375px) {
  .modal > .modal-content {
    width: 355px;
  }
}

/* Very Small devices only */
@media all and (max-width: 320px) {
  .modal > .modal-content {
    width: 300px;
  }
}
