/* OUR WORK PAGE */
.grid-work {
  display: grid;
  margin: 1rem 2rem;
  grid-template-areas:
    "logos logos"
    "screenshots info";
  grid-template-columns: minmax(400px, 1fr) 1fr;
  grid-template-rows: auto minmax(450px, auto);
  gap: 2rem 1rem;
}

.grid-work-logos {
  grid-area: logos;
}

.grid-work-screenshots {
  grid-area: screenshots;
}

.grid-work-info {
  grid-area: info;
  animation: animation-pop-in 0.8s;
  animation-fill-mode: both;
  animation-delay: 3.5s;
}

p.top-blurb {
  font-family: "Montserrat-SemiBold";
  text-align: center;
}

/* COMPANY LOGOS  */
.flex-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

img.companylogo {
  height: 75px;
  width: 100px;
  border: 2px solid lightgrey;
  margin: 5px;
  filter: grayscale(1);
  animation: animation-pop-in 0.5s;
  animation-fill-mode: both;
}

/* This is for the animation so they pop in one after the other */
img.companylogo:nth-of-type(1) {
  animation-delay: 0.5s;
}
img.companylogo:nth-of-type(2) {
  animation-delay: 0.75s;
}
img.companylogo:nth-of-type(3) {
  animation-delay: 1s;
}
img.companylogo:nth-of-type(4) {
  animation-delay: 1.25s;
}
img.companylogo:nth-of-type(5) {
  animation-delay: 1.5s;
}
img.companylogo:nth-of-type(6) {
  animation-delay: 1.75s;
}
img.companylogo:nth-of-type(7) {
  animation-delay: 2s;
}
img.companylogo:nth-of-type(8) {
  animation-delay: 2.25s;
}
img.companylogo:nth-of-type(9) {
  animation-delay: 2.5s;
}
img.companylogo:nth-of-type(10) {
  animation-delay: 2.75s;
}
img.companylogo:nth-of-type(11) {
  animation-delay: 3s;
}
img.companylogo:nth-of-type(12) {
  animation-delay: 3.25s;
}
img.companylogo:nth-of-type(13) {
  animation-delay: 3.5s;
}
img.companylogo:nth-of-type(14) {
  animation-delay: 3.75s;
}
img.companylogo:nth-of-type(15) {
  animation-delay: 4s;
}
img.companylogo:nth-of-type(16) {
  animation-delay: 4.25s;
}
img.companylogo:nth-of-type(17) {
  animation-delay: 4.5s;
}
img.companylogo:nth-of-type(18) {
  animation-delay: 4.75s;
}
img.companylogo:nth-of-type(19) {
  animation-delay: 5s;
}
img.companylogo:nth-of-type(20) {
  animation-delay: 5.25s;
}

img.companylogo:hover {
  filter: grayscale(0);
  cursor: pointer;
  border: 2px solid var(--nav-highlight-bg-hover-color);
}

img.selected {
  filter: grayscale(0);
  border: 2px solid var(--nav-bg-color);
}

/* WORK INFO SECTIONS */
.work-info-section {
  animation: animation-scale-in 0.5s;
  animation-fill-mode: both;
}

.work-info-section:not(:first-of-type) {
  margin-top: 35px;
}

/* This is for the animation so they pop in one after the other */
.work-info-section:nth-of-type(1) {
  animation-delay: 3.5s;
}
.work-info-section:nth-of-type(2) {
  animation-delay: 3.75s;
}
.work-info-section:nth-of-type(3) {
  animation-delay: 4s;
}
.work-info-section:nth-of-type(4) {
  animation-delay: 4.25s;
}

/* SLIDESHOW */
* {
  box-sizing: border-box;
}

/* Slideshow container */
.slideshow-container {
  position: relative;
  margin: auto;
  font-family: "Montserrat-SemiBold";
  border: 1px solid lightgray;
  animation: animation-pop-in 0.8s;
  animation-fill-mode: both;
  animation-delay: 3s;
}

/* Hide the images by default */
.slideshow-image-container {
  display: none;
}

.slide-image {
  cursor: zoom-in;
  width: 100%;
  height: auto;
}

/* Next & previous buttons */
.slideshow-container > .prev,
.slideshow-container > .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: darkgrey;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  text-decoration: none;
}

/* Position the "next button" to the right */
.slideshow-container > .next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a background */
.slideshow-container > .prev:hover,
.slideshow-container > .next:hover {
  color: white;
  background-color: var(--nav-bg-hover-color);
}

/* Caption text */
.slideshow-caption {
  background-color: var(--nav-bg-color);
  color: white;
  padding: 0.5rem 1rem;
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.slideshow-number {
  color: darkgray;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.slideshow-dots {
  animation: animation-pop-in 0.8s;
  animation-fill-mode: both;
  animation-delay: 3.25s;
}

.slideshow-dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: var(--nav-bg-color);
}

.slideshow-dot:hover {
  background-color: var(--nav-bg-hover-color);
}

/* Fading animation */
.slideshow-container > .fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: animation-fade;
  animation-duration: 1.5s;
}

/* ANIMATIONS */
/* Slide show fading between images */
@keyframes animation-fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* Elements pop in effect */
@keyframes animation-pop-in {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  85% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Elements scale in without pop effect */
@keyframes animation-scale-in {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}

/* ======================================================= */
/*                        OTHER DEVICES                    */
/* ======================================================= */

/* Very Large Devices (TV / wide screen) */
@media all and (min-width: 1600px) {
  p.top-blurb {
    text-align: center;
    font-size: 1.5rem;
  }

  img.companylogo {
    height: 150px;
    width: 200px;
    border: 2px solid lightgrey;
    filter: grayscale(1);
  }

  img.selected {
    filter: grayscale(0);
    border: 2px solid var(--nav-bg-color);
  }

  .flex-logos {
    justify-content: center;
  }
}

/* Standard Devices (small laptops & iPad Pro) */
@media all and (max-width: 1300px) {
  .flex-logos {
    justify-content: center;
  }
}

/*  Medium Devices and smaller */
@media all and (max-width: 768px) {
  .grid-work {
    grid-template-areas:
      "logos"
      "screenshots"
      "info";
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    gap: 2rem;
  }
}

/* Small devices only */
@media all and (max-width: 660px) {
  .slide-image {
    cursor: default;
  }

  @media all and (max-width: 375px) {
    .slideshow-image-container {
      margin-bottom: 30px;
    }

    /* Next & previous buttons */
    .slideshow-container > .prev,
    .slideshow-container > .next {
      margin-top: -45px;
    }
  }
}
